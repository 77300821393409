import React from "react"

import { StaticImage } from "gatsby-plugin-image"

export default function Lightbox({
  closeLightbox,
  src,
  width,
  height,
  alt,
  id,
  changeId,
}) {
  const renderSwitch = () => {
    switch (id) {
      case 1:
        return (
          <StaticImage
            src="../images/gallery1.png"
            width={553}
            height={229}
            placeholder="blurred"
            quality={95}
            imgStyle={{ objectFit: "contain" }}
            style={{ gridArea: "1/1" }}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Clock"
          />
        )
        break
      case 2:
        return (
          <StaticImage
            src="../images/gallery2.png"
            width={553}
            height={229}
            placeholder="blurred"
            quality={95}
            imgStyle={{ objectFit: "contain" }}
            style={{ gridArea: "1/1" }}
            formats={["AUTO", "WEBP", "AVIF"]}
            className="gallery2"
            alt="Garden"
          />
        )
        break
      case 3:
        return (
          <StaticImage
            src="../images/gallery3.png"
            width={556}
            height={470}
            placeholder="blurred"
            quality={95}
            imgStyle={{ objectFit: "contain" }}
            style={{ gridArea: "1/1" }}
            formats={["AUTO", "WEBP", "AVIF"]}
            className="gallery3"
            alt="Pool"
          />
        )
        break
      case 4:
        return (
          <StaticImage
            src="../images/gallery4.png"
            width={1121}
            height={218}
            placeholder="blurred"
            quality={95}
            imgStyle={{ objectFit: "contain" }}
            style={{ gridArea: "1/1" }}
            formats={["AUTO", "WEBP", "AVIF"]}
            className="gallery4"
            alt="Family"
          />
        )
        break
      default:
        return <></>
    }
  }

  return (
    <>
      <div className="lightbox">
        <p onClick={e => closeLightbox()} className="lightbox-close p-mulish">
          X
        </p>
        <div style={{ display: "none" }} className="lightbox-arrows">
          <p
            className="lightbox-arrow lightbox-arrow-previous p-mulish"
            onClick={e => {
              const count = id - 1
              changeId(count)
            }}
          >
            Previous
          </p>
          <p
            className="lightbox-arrow lightbox-arrow-next p-mulish"
            onClick={e => {
              const count = id + 1
              changeId(count)
            }}
          >
            Next
          </p>
          <p className="p-mulish lightbox-amount">{id}/4</p>
        </div>
        <div className="lightbox-img">
          <div className="lightbox-img-container">{renderSwitch()}</div>
        </div>
        <div className="lightbox-background"></div>
      </div>
    </>
  )
}
