import React, { useState } from "react"

import { StaticImage } from "gatsby-plugin-image"
import SEO from "../components/SeoComponent"
import Stars from "../assets/stars.svg"
import Lightbox from "../components/Lightbox"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styles from "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

import { useTranslation } from "react-i18next"
import { LocalizedLink as Link } from "gatsby-theme-i18n"

import Layout from "../components/layout"

const Index = ({ data }) => {
  const [lightbox, setLightbox] = useState(false)

  const { t } = useTranslation()

  if (typeof window !== "undefined") {
    document.body.style.overflow = "visible"
  }

  const [id, setId] = useState(null)

  const showcase_room = data.allContentfulKamer.edges[0].node

  console.log(useTranslation())

  return (
    <Layout path={"Home"}>
      <SEO title={"Home"} />
      <section className="home-header">
        <div>
          <Stars />
        </div>
        <p className="home-rating p-mulish shadow">Superior Hotel</p>
        <h1 className="home-title h1-bold shadow">Fleur de Lys</h1>
        <p className="home-location p-mulish shadow">{t("locatie")}</p>
        <Link to="/rooms">
          <p className="button--kamers p-mulish shadow">{t("home.1")}</p>
        </Link>
        <Carousel
          className="slider-container"
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          swipeable={true}
          autoFocus={true}
          swipeable={true}
          emulateTouch={true}
          interval={8000}
          autoPlay
          infiniteLoop={true}
        >
          <div className="carousel-slide">
            <StaticImage
              src="../images/castle.png"
              width={2046}
              height={1064}
              quality={95}
              placeholder="blurred"
              imgStyle={{ objectFit: "cover", height: "100%" }}
              style={{ gridArea: "1/1", opacity: "0.5" }}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Familie"
            />
          </div>

          <div className="carousel-slide">
            <StaticImage
              src="../images/familieBig.png"
              width={1292}
              height={582}
              quality={95}
              placeholder="blurred"
              imgStyle={{ objectFit: "cover", height: "100%" }}
              style={{ gridArea: "1/1", opacity: "0.5" }}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Familie"
            />
          </div>
          <div className="carousel-slide">
            <StaticImage
              src="../images/header.png"
              width={1280}
              height={853}
              quality={95}
              placeholder="none"
              imgStyle={{ objectFit: "cover", height: "100%" }}
              style={{ gridArea: "1/1", opacity: "0.5" }}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Zwembad"
            />
          </div>
        </Carousel>
      </section>
      <section className="home-introduction alignVertical">
        <h1 className="introduction-title h2">{t("home.3")}</h1>
        <div className="triangle introduction-triangle"></div>
        <p
          className="introduction-text"
          dangerouslySetInnerHTML={{
            __html: t("home.4", {
              interpolation: { escapeValue: false },
            }),
          }}
        ></p>
      </section>
      <section className="perks">
        <div className="perks-img-container">
          <StaticImage
            src="../images/ontbijt.png"
            width={670}
            height={499}
            placeholder="blurred"
            quality={95}
            imgStyle={{ objectFit: "cover" }}
            style={{
              gridArea: "1/1",
              // You can set a maximum height for the image, if you wish.
              // maxHeight: 600,
            }}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Ontbijt"
          />
        </div>

        <div className="home-perks">
          <h1 className="perks-title h2">{t("home.5")}</h1>
          <ul className="perks__list">
            <li className="perks__list-item flex-horizontal">
              <div className="triangle"></div>
              {t("home.6")}
            </li>
            <li className="perks__list-item flex-horizontal">
              <div className="triangle"></div>
              {t("home.7")}
            </li>
            <li className="perks__list-item flex-horizontal">
              <div className="triangle"></div>
              {t("home.8")}
            </li>
            <li className="perks__list-item flex-horizontal">
              <div className="triangle"></div>
              {t("home.9")}
            </li>
            <li className="perks__list-item flex-horizontal">
              <div className="triangle"></div>
              {t("home.10")}
            </li>
            <li className="perks__list-item flex-horizontal">
              <div className="triangle"></div>
              {t("home.11")}
            </li>
            <li className="perks__list-item flex-horizontal">
              <div className="triangle"></div>
              {t("home.12")}
            </li>
          </ul>
          <Link className="button p-mulish" to="/rooms">
            {t("home.13")}
          </Link>
        </div>
        <div className="perks-img-container perks-img-container--last">
          <StaticImage
            src="../images/tuin.png"
            width={211}
            height={499}
            quality={95}
            imgStyle={{ objectFit: "cover", width: "100%" }}
            style={{
              gridArea: "1/1",
              // You can set a maximum height for the image, if you wish.
              // maxHeight: 600,
            }}
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Ontbijt"
          />
        </div>
      </section>
      <section className="showcase">
        <div className="showcase-background"></div>
        <div className="showcase-img">
          <Img
            fluid={showcase_room.fotos[0].fluid}
            key={showcase_room.fotos[0].contentful_id}
            alt={showcase_room.naam}
            imgStyle={{ objectFit: "cover" }}
          />
        </div>
        <div className="showcase-content">
          <Link className="showcase-rooms p-mulish" to="/rooms">
            {t("home.14")}
          </Link>
          <h1 className="showcase-title h2">
            {showcase_room.naam} {t("detail.2")}
          </h1>
          <p style={{ marginTop: "2rem" }} className="showcase-text"></p>
          <Link
            className="button showcase-button p-mulish"
            to={`/rooms/${showcase_room.id}`}
          >
            {t("home.15")}
          </Link>
          <div className="showcase-quote">
            <p className="showcase-quote-text">{t("home.16")}</p>
            <p className="quote-author">- Tamara</p>
          </div>
        </div>
      </section>
      <section className="information information-extra-wrapper">
        <h1 className="hidden">Informatie</h1>
        <article className="information-about">
          <h2 className="about-title h2-title">{t("home.17")}</h2>
          <div className="grey-bar">
            <div className="yellow-bar"></div>
          </div>
          <div style={{ width: "100%", display: "grid" }}>
            <StaticImage
              src="../images/familie.png"
              width={305}
              height={221}
              quality={95}
              placeholder="blurred"
              imgStyle={{ objectFit: "cover" }}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Foto van de familie"
            />
          </div>

          <p
            className="information-about-text information-text"
            dangerouslySetInnerHTML={{
              __html: t("home.20", {
                interpolation: { escapeValue: false },
              }),
            }}
          ></p>
        </article>
        <article className="information-location">
          <h2 className="about-title h2-title">{t("home.18")}</h2>
          <div className="grey-bar">
            <div className="yellow-bar"></div>
          </div>
          <div style={{ width: "100%", display: "grid" }}>
            <StaticImage
              src="../images/locatie.png"
              width={305}
              height={221}
              quality={95}
              placeholder="blurred"
              imgStyle={{ objectFit: "cover" }}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Google maps foto van de locatie"
            />
          </div>
          <p
            className="information-text"
            dangerouslySetInnerHTML={{
              __html: t("home.21", {
                interpolation: { escapeValue: false },
              }),
            }}
          ></p>
          <ul className="location-list">
            <li className="location-list__item flex-horizontal">
              <div className="triangle"></div>
              <p
                className="information-text"
                dangerouslySetInnerHTML={{
                  __html: t("home.22", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              ></p>
            </li>
            <li className="location-list__item flex-horizontal">
              <div className="triangle"></div>
              <p
                className="information-text"
                dangerouslySetInnerHTML={{
                  __html: t("home.23", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              ></p>
            </li>
            <li className="location-list__item flex-horizontal">
              <div className="triangle"></div>
              <p
                className="information-text"
                dangerouslySetInnerHTML={{
                  __html: t("home.24", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              ></p>
            </li>
            <li className="location-list__item flex-horizontal">
              <div className="triangle"></div>
              <p
                className="information-text"
                dangerouslySetInnerHTML={{
                  __html: t("route.1", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              ></p>
            </li>
          </ul>
        </article>
        <article className="information-extra">
          <h2 className="about-title h2-title">{t("home.19")}</h2>
          <div className="grey-bar">
            <div className="yellow-bar"></div>
          </div>
          <div style={{ width: "100%", display: "grid" }}>
            <StaticImage
              src="../images/owners.png"
              width={305}
              height={221}
              quality={95}
              placeholder="blurred"
              imgStyle={{ objectFit: "cover" }}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Foto van de 2 eigenaren"
            />
          </div>
          <p className="information-text">{t("home.25")}</p>
          <ul className="location-list">
            <li className="location-list__item flex-horizontal">
              <div className="triangle"></div>
              <p
                className="information-text"
                dangerouslySetInnerHTML={{
                  __html: t("home.26", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              ></p>
            </li>
            <li className="location-list__item flex-horizontal">
              <div className="triangle"></div>
              <p
                className="information-text"
                dangerouslySetInnerHTML={{
                  __html: t("home.27", {
                    interpolation: { escapeValue: false },
                  }),
                }}
              ></p>
            </li>
          </ul>
        </article>
      </section>
      <section className="extra-info wrapper">
        <h1 className="hidden">Extra informatie rondom onze activiteiten</h1>

        <div className="activiteiten-one">
          <p>{t("extra.11")}</p>
          <dl>
            <dt>{t("extra.12")}</dt>
            <dd>{t("extra.13")}</dd>
            <dd>{t("extra.14")}</dd>
            <dt>{t("extra.15")}</dt>
            <dt>{t("extra.16")}</dt>
            <dt>{t("extra.17")}</dt>
            <dd>{t("extra.18")}</dd>
            <dt>{t("extra.19")}</dt>
            <dt>{t("extra.20")}</dt>
          </dl>
        </div>
        <div className="activiteiten-two">
          <p>{t("extra.1")}</p>
          <dl>
            <dt>{t("extra.2")}</dt>
            <dd>{t("extra.3")}</dd>
            <dt>{t("extra.4")}</dt>
            <dd>{t("extra.5")}</dd>
            <dt>{t("extra.6")}</dt>
            <dd>{t("extra.7")}</dd>
            <dt>{t("extra.8")}</dt>
            <dd>{t("extra.9")}</dd>
            <dd>{t("extra.10")}</dd>
          </dl>
        </div>
      </section>
      <section className="gallery">
        <h1 className="h2">{t("home.28")}</h1>
        <div className="gallery-grid">
          <div
            className="gallery1"
            onClick={e => {
              setId(1)
              setLightbox(true)
            }}
          >
            <StaticImage
              src="../images/gallery1.png"
              width={553}
              height={229}
              placeholder="blurred"
              quality={95}
              imgStyle={{ objectFit: "cover" }}
              style={{ gridArea: "1/1" }}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Klok"
            />
          </div>
          <div
            onClick={e => {
              setId(2)
              setLightbox(true)
            }}
            className="gallery2"
          >
            <StaticImage
              src="../images/gallery2.png"
              width={553}
              height={229}
              placeholder="blurred"
              quality={95}
              imgStyle={{ objectFit: "cover" }}
              style={{ gridArea: "1/1" }}
              formats={["AUTO", "WEBP", "AVIF"]}
              className="gallery2"
              alt="Garden"
            />
          </div>
          <div
            onClick={e => {
              setId(3)
              setLightbox(true)
            }}
            className="gallery3"
          >
            <StaticImage
              src="../images/gallery3.png"
              width={556}
              height={470}
              placeholder="blurred"
              quality={95}
              imgStyle={{ objectFit: "cover" }}
              style={{ gridArea: "1/1" }}
              formats={["AUTO", "WEBP", "AVIF"]}
              className="gallery3"
              alt="Pool"
            />
          </div>
          <div
            onClick={e => {
              setId(4)
              setLightbox(true)
            }}
            className="gallery4"
          >
            <StaticImage
              src="../images/gallery4.png"
              width={1121}
              height={218}
              placeholder="blurred"
              quality={95}
              imgStyle={{ objectFit: "cover" }}
              style={{ gridArea: "1/1" }}
              formats={["AUTO", "WEBP", "AVIF"]}
              className="gallery4"
              alt="Family"
            />
          </div>
        </div>
      </section>
      {lightbox ? (
        <Lightbox
          id={id}
          closeLightbox={e => setLightbox(false)}
          changeId={id => setId(id)}
        />
      ) : (
        ""
      )}
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulKamer(filter: { showcaseRoom: { eq: true } }) {
      edges {
        node {
          badkamer
          beschrijving {
            beschrijving
          }
          prijsDoubleweekDag
          prijsDoubleweekend
          prijsSingleweekDag
          prijsSingleweekend
          naam
          fotos {
            description
            contentful_id
            resize {
              src
              height
              aspectRatio
              width
            }
            fluid {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
          id
        }
      }
    }
  }
`

export default Index
